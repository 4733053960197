import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DownloadPage = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const fileUrl = urlParams.get('file');

        if (fileUrl) {
            const downloadFile = async () => {
                try {
                    const response = await fetch(fileUrl);
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const filename = fileUrl.split('/').pop();
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('Download failed', error);
                }
            };

            downloadFile();
        }
    }, [location]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-white p-6">
            <div className="text-center p-10 max-w-3xl w-full bg-gradient-to-r rounded-2xl shadow-2xl"
                style={{ background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)' }}>
                         <div className="mt-8">
                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 mx-auto">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g clipPath="url(#clip0_901_2655)">
                                <path d="M26.8901 19.3101C29.2701 20.0901 31.0001 22.3501 31.0001 25.0001C31.0001 28.3101 28.3101 31.0001 25.0001 31.0001C21.6901 31.0001 19.0001 28.3101 19.0001 25.0001C19.0001 21.6901 21.6901 19.0001 25.0001 19.0001C25.6601 19.0001 26.3001 19.1101 26.8901 19.3101Z" fill="#FFC44D"></path>
                                <path d="M27 19L26.89 19.31C26.3 19.11 25.66 19 25 19C21.69 19 19 21.69 19 25C19 28.31 21.69 31 25 31H2C1 31 1 30 1 30V2C1 1 2 1 2 1H17V10C17 10 17 11 18 11H27V19Z" fill="#FFE6EA"></path>
                                <path d="M26 9C27 10 27 11 27 11H18C17 11 17 10 17 10V1C17 1 18 1 19 2L26 9Z" fill="#668077"></path>
                                <path d="M27 16V11C27 11 27 10 26 9L19 2C18 1 17 1 17 1M17 1H2C2 1 1 1 1 2V30C1 30 1 31 2 31H18M17 1V10C17 10 17 11 18 11H23M27 25L25 27M25 27L23 25M25 27V23M31 25C31 21.687 28.313 19 25 19C21.687 19 19 21.687 19 25C19 28.313 21.687 31 25 31C28.313 31 31 28.313 31 25Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_901_2655">
                                    <rect width="32" height="32" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </g>
                    </svg>
                </div>
                <h1 className="text-5xl font-extrabold text-black mb-6">
                Your audio file is being downloaded. Check your download folder 

                </h1>

            

       
            </div>
        </div>
    );
};

export default DownloadPage;
